import { fetchWithAuth } from './apiClient'

export const createWorkspace = async (ownerId, name) => {
    const response = await fetchWithAuth(`/workspace/create?owner_id=${ownerId}`, {
        method: 'POST',
        body: JSON.stringify({ name })
    });
    return response.json();
};

export const getWorkspaces = async (ownerId) => {
    const response = await fetchWithAuth(`/workspace/owner/all?owner_id=${ownerId}`, {
        method: 'GET'
    });
    return response.json();
};

export const deleteWorkspace = async (workspaceId) => {
    const response = await fetchWithAuth(`/workspace/${workspaceId}`, {
        method: 'DELETE'
    });
    return response.json();
};
