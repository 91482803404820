// src/features/workspace/workspaceSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createWorkspace, deleteWorkspace } from '../../../api/workspaceApi';
import { updateUserWorkspace, getUserDetails } from '../../../api/userApi';

export const fetchWorkspaces = createAsyncThunk(
    'workspaces/fetchWorkspaces',
    async () => {
        const response = await getUserDetails();
        return response;
    }
);

export const addWorkspace = createAsyncThunk(
    'workspaces/addWorkspace',
    async ({ ownerId, name }) => {
        const response = await createWorkspace(ownerId, name);
        return response;
    }
);

export const removeWorkspace = createAsyncThunk(
    'workspaces/removeWorkspace',
    async (workspaceId) => {
        await deleteWorkspace(workspaceId);
        return workspaceId;
    }
);

export const updateCurrentWorkspace = createAsyncThunk(
    'workspaces/updateCurrentWorkspace',
    async (workspace_id) => {

        debugger
        const response = await updateUserWorkspace(workspace_id);
        return response;
    }
);

const workspaceSlice = createSlice({
    name: 'workspaces',
    initialState: {
        workspaces: [],
        currentWorkspace: null,
        status: 'idle',
        error: null
    },
    reducers: {
        setCurrentWorkspace(state, action) {
            state.currentWorkspace = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workspaces = action.payload.workspaces;
                const currentWorkspaceArray = action.payload.workspaces.filter(workspace => workspace.id === action.payload.current_workspace);
                const currentWorkspace = currentWorkspaceArray.length > 0 ? currentWorkspaceArray[0] : null;
                state.currentWorkspace = currentWorkspace;
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addWorkspace.fulfilled, (state, action) => {
                state.workspaces.push(action.payload);
            })
            .addCase(removeWorkspace.fulfilled, (state, action) => {
                state.workspaces = state.workspaces.filter(workspace => workspace.id !== action.payload);
            })
            .addCase(updateCurrentWorkspace.fulfilled, (state, action) => {
                const currentWorkspaceArray = state.workspaces.filter(workspace => workspace.id === action.payload.current_workspace);
                const currentWorkspace = currentWorkspaceArray.length > 0 ? currentWorkspaceArray[0] : null;
                state.currentWorkspace = currentWorkspace;
            });
    }
});

export const { setCurrentWorkspace } = workspaceSlice.actions;

export default workspaceSlice.reducer;
