import { API_BASE_URL } from '../constants';

let getToken = null;

export const initializeApiClient = (getTokenFunction) => {
  getToken = getTokenFunction;
};

export const fetchWithAuth = async (endpoint, options = {}) => {
  if (!getToken) {
    throw new Error('API client not initialized with auth token getter');
  }

  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    ...options.headers,
  };

  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`HTTP error! status: ${response.status}, detail: ${errorData.detail}`);
  }

  return response;
};