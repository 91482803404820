import { fetchWithAuth } from './apiClient';

export const updateUserWorkspace = async (workspaceId) => {

    console.log(workspaceId)

    const response = await fetchWithAuth(`/user`, {
        method: 'PUT',
        body: JSON.stringify({ current_workspace: workspaceId })
    });
    return response.json();
};

export const getUserDetails = async (userId) => {
    const response = await fetchWithAuth(`/user`, {
        method: 'GET'
    });
    return response.json();
};



