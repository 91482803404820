import { fetchWithAuth } from './apiClient';

export const createAgent = async (workspaceId, formData) => {
    return fetchWithAuth(`/agents/create/${workspaceId}`, {
        method: 'POST',
        body: JSON.stringify(formData)
    });
};

export const getAllAgents = async (workspaceId) => {
    return fetchWithAuth(`/agents/all/${workspaceId}`, {
        method: 'GET'
    });
};

export const getAgentVoices = async () => {
    return fetchWithAuth('/agents/voices/', {
        method: 'GET'
    });
};

export const deleteAgent = async (agentId) => {
    return fetchWithAuth(`/agents/${agentId}`, {
        method: 'DELETE'
    });
};

export const updateAgent = async (agentId, agentData) => {
    try {
        return await fetchWithAuth(`/agents/${agentId}`, {
            method: 'PUT',
            body: JSON.stringify(agentData)
        });
    } catch (error) {
        console.error('Error updating agent:', error);
        return { ok: false };
    }
};

export const fetchAccessToken = async (agentId, voiceAiAgentId) => {
    try {
        const response = await fetchWithAuth('/RegisterWebCall/', {
            method: 'POST',
            body: JSON.stringify({
                agent_id: agentId,
                voice_ai_agent_id: voiceAiAgentId
            })
        });
        const data = await response.json();
        console.log('Success:', data);
        return data;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};