import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { themeChange } from 'theme-change'
import initializeApp from './app/init';
import { ClerkProvider, SignIn, SignUp, useAuth } from '@clerk/clerk-react';
import { CLERK_PUBLISHABLE_KEY } from './constants';
import { initializeApiClient } from './api/apiClient';

const Layout = lazy(() => import('./containers/Layout'))

initializeApp()

function App() {
  useEffect(() => {
    themeChange(false)
  }, [])

  return (
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
      <Router>
        <AppRoutes />
      </Router>
    </ClerkProvider>
  )
}

function AppRoutes() {
  const { getToken } = useAuth();

  useEffect(() => {
    initializeApiClient(getToken);
  }, [getToken]);

  return (
    <Routes>
      <Route
        path="/app/*"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/sign-in/*" 
        element={
          <div className="min-h-screen bg-base-200 flex items-center justify-center">
            <SignIn routing="path" path="/sign-in" />
          </div>
        } 
      />
      <Route 
        path="/sign-up/*" 
        element={
          <div className="min-h-screen bg-base-200 flex items-center justify-center">
            <SignUp routing="path" path="/sign-up" />
          </div>
        } 
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <Navigate to="/app/dashboard" replace />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

function ProtectedRoute({ children }) {
  const { isSignedIn, isLoaded } = useAuth();
  const location = useLocation();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
}

export default App
